import React, { ChangeEvent, useState, useEffect } from 'react';
import { TextField, FormControl } from '@mui/material';

interface DistanceInputProps {
    min: number;
    max: number;
    onChange: (value: string) => void;
}

const DistanceInput: React.FC<DistanceInputProps> = ({ min, max,onChange }) => {
    const [value, setValue] = useState<string>('');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        // Revalidate the value when min or max changes
        const errorMessage = validateValue(value);
        setError(errorMessage);
    }, [min, max]);

    // Validate the input value
    const validateValue = (value: string): string => {
        const numValue = parseFloat(value);

        if (isNaN(numValue)) {
            return '';
        } else if (numValue < min || numValue > max) {
            return `Value must be between ${min} and ${max}`;
        } else {
            return '';
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setValue(value);

        const errorMessage = validateValue(value);
        setError(errorMessage);
        onChange(value)
    };

    return (
        <FormControl fullWidth margin="normal">
            <TextField
                label="Select Distance"
                type="number"
                value={value}
                onChange={handleChange}
                error={!!error}
                helperText={error}
                inputProps={{ min, max, step: '0.1' }} // Adjust step as needed
            />
        </FormControl>
    );
};

export default DistanceInput;
