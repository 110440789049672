import React from "react";
import Graph from "graphology";
import {Attributes} from "graphology-types";

export function graphWholeReset(graphRef: React.MutableRefObject<Graph<Attributes, Attributes, Attributes>>, data: any, haversineDistance: (coord1: any, coord2: any) => number, value: number, allowedConnectionNumber: number, startNode: string | null | undefined, endNode: string | null | undefined) {
    let i;
    graphRef.current.clear();
    for (i = 0; i < data.length; i++) {
        graphRef.current.addNode(data[i]["id"], {
            size: 4,
            label: data[i]["name"],
            x: data[i]["longitude"],
            y: data[i]["latitude"],
        });
    }
    for (i = 0; i < data.length; i++) {
        for (let j = 0; j < data.length; j++) {
            if (
                i !== j &&
                !(graphRef.current.edge(data[i]["id"], data[j]["id"]) || graphRef.current.edge(data[j]["id"], data[i]["id"]))
            ) {
                const distance = haversineDistance(
                    {latitude: data[i]["latitude"], longitude: data[i]["longitude"]},
                    {latitude: data[j]["latitude"], longitude: data[j]["longitude"]},
                );
                if (
                    distance < value &&
                    (graphRef.current.degree(data[i]["id"]) < allowedConnectionNumber ||
                        graphRef.current.degree(data[j]["id"]) < allowedConnectionNumber)
                ) {
                    graphRef.current.addEdgeWithKey(`${data[i]["id"]};${data[j]["id"]}`, data[i]["id"], data[j]["id"], {size: 2});
                }
            }
        }
    }
    let maxDistance = 0;
    let minDistance = Infinity;

    for (let i = 0; i < data.length; i++) {
        for (let j = i + 1; j < data.length; j++) {
            const distance = haversineDistance(
                {latitude: data[i]["latitude"], longitude: data[i]["longitude"]},
                {latitude: data[j]["latitude"], longitude: data[j]["longitude"]},
            );
            if (distance > maxDistance) {
                maxDistance = distance;
            }
            if (distance < minDistance) {
                minDistance = distance;
            }
        }
    }
    if (startNode) {
        graphRef.current.setNodeAttribute(startNode, "color", "yellow")
    }
    if (endNode) {
        graphRef.current.setNodeAttribute(endNode, "color", "blue")
    }
    return {maxDistance, minDistance};
}
