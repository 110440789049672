import * as XLSX from "xlsx";
import {ReturnData} from "../App";
import React from "react";
import Graph from "graphology";
import {Attributes} from "graphology-types";

export function exportHandler(returnData: ReturnData) {
    const workbook = XLSX.utils.book_new();
    const workSheetData: string[][] = [];
    const headerRow = [];
    const dataRow = []
    if (returnData.bfs) {
        headerRow.push("Bfs Steps")
        headerRow.push("Bfs shortest Path")
        headerRow.push("Bfs shortestPath Length")
        dataRow.push(returnData.bfs.steps!.toString())
        dataRow.push(returnData.bfs.shortestPath!.join(",\n").toString());
        dataRow.push(returnData.bfs.pathSteps!.toString())
    }

    if (returnData.dfs) {
        headerRow.push("Dfs Steps")
        headerRow.push("Dfs shortest Path")
        headerRow.push("Dfs shortestPath Length")
        dataRow.push(returnData.dfs.steps!.toString())
        dataRow.push(returnData.dfs.shortestPath!.join(",\n").toString());
        dataRow.push(returnData.dfs.pathSteps!.toString())
    }
    if (returnData.aStar) {
        headerRow.push("AStar Steps")
        headerRow.push("AStar shortest Path")
        headerRow.push("AStar shortestPath Length")
        dataRow.push(returnData.aStar.steps!.toString())
        dataRow.push(returnData.aStar.shortestPath!.join(",\n").toString());
        dataRow.push(returnData.aStar.pathSteps!.toString())
    }
    if (returnData.dijykstra) {
        headerRow.push("Dijykstra Steps")
        headerRow.push("Dijykstra shortest Path")
        headerRow.push("Dikykstra shortestPath Length")
        dataRow.push(returnData.dijykstra.steps!.toString())
        dataRow.push(returnData.dijykstra.shortestPath!.join(",\n").toString());
        dataRow.push(returnData.dijykstra.pathSteps!.toString())
    }
    workSheetData.push(headerRow);
    workSheetData.push(dataRow)
    const columnWidths = workSheetData[0].map((_, colIdx) => {
        return {
            wch: Math.max(
                ...workSheetData.map(row => (row[0] ? row[0].toString().length : 0))
            ) + 2

        };
    });
    const rowHeights = workSheetData.map(row => {
        const maxLines = Math.max(
            ...row.map(cell => (cell ? cell.toString().split('\n').length : 1))
        );

        const baseHeight = 15;

        return {hpt: maxLines * baseHeight};
    });


    const worksheet = XLSX.utils.aoa_to_sheet(workSheetData);
    worksheet['!cols'] = columnWidths;
    worksheet['!rows'] = rowHeights;

    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");
    XLSX.writeFile(workbook, "data.xlsx")
}

export function haversineDistance(coord1: any, coord2: any) {
    const toRadians = (degree: number) => {
        return (degree * Math.PI) / 180;
    };
    const R = 6371;

    const dLat = toRadians(coord2.latitude - coord1.latitude);
    const dLon = toRadians(coord2.longitude - coord1.longitude);

    const lat1 = toRadians(coord1.latitude);
    const lat2 = toRadians(coord2.latitude);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
}

export function heuristicCalc(graphRef: React.MutableRefObject<Graph<Attributes, Attributes, Attributes>>, nodeB: any, nodeA: any) {
    let d1 = Math.abs(graphRef.current.getNodeAttribute(nodeB, "x") - graphRef.current.getNodeAttribute(nodeA, "x"));
    let d2 = Math.abs(graphRef.current.getNodeAttribute(nodeB, "y") - graphRef.current.getNodeAttribute(nodeA, "y"));

    return d1 + d2;
}

export function readFileData(reader: FileReader, file: any, setData: (value: any) => void) {
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, {type: "string"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        console.log(parsedData);
        setData(parsedData);
    };
}

