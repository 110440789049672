export class PriorityQueue<T> {
    private values: { element: T; priority: number }[];

    constructor() {
        this.values = [];
    }

    // Add an element to the priority queue with a priority
    enqueue(element: T, priority: number): void {
        this.values.push({ element, priority });
        this.sort();
    }

    // Remove and return the element with the highest priority (lowest value)
    dequeue(): { element: T; priority: number } | undefined {
        return this.values.shift(); // Remove the first element (the smallest priority)
    }

    // Check if the queue is empty
    isEmpty(): boolean {
        return this.values.length === 0;
    }

    // Sort the queue based on priority (lowest priority first)
    private sort(): void {
        this.values.sort((a, b) => a.priority - b.priority);
    }
}