import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import {SigmaContainer, useRegisterEvents} from "@react-sigma/core";
import Graph from "graphology";
import "@react-sigma/core/lib/react-sigma.min.css";
import * as XLSX from "xlsx";
import {Button, createTheme, CssBaseline, Input, ThemeProvider} from "@mui/material";
import {PriorityQueue} from "./PriorityQueue";
import DistanceInput from "./components/DistanceInput";
import {graphWholeReset} from "./scripts/graphMethods";
import {exportHandler, haversineDistance, heuristicCalc, readFileData} from "./scripts/dataMethods";

export interface dataDetails {
    shortestPath?: (string | null | undefined)[],
    steps?: number,
    pathSteps?: number
}

export interface ReturnData {
    dfs?: dataDetails,
    bfs?: dataDetails,
    aStar?: dataDetails,
    dijykstra?: dataDetails
}


function App() {
    const traversedEdgeColor = "#980404"
    const normalEdgeColor = "#134901"
    const routeEdgeColor = "#E4A6A6"
    const [startNode, setStartNode] = useState<string | null | undefined>(null);
    const [endNode, setEndNode] = useState<string | null | undefined>(null);
    const [startIsSet, setStartIsSet] = useState<boolean>(false);
    const [returnData, setReturnData] = useState<ReturnData>({});

    let stopSolving = false;
    const [data, setData] = useState<any>([]);
    const [value, setValue] = React.useState<number>(20);
    const [allowedConnectionNumber, setAllowedConnectionNumber] =
        React.useState<number>(20);
    const [blockedEdges, setBlockedEdges] = useState<string[]>([]);
    const [minSlider, setMinSlider] = useState(0);
    const [maxSlider, setMaxSlider] = useState(100);

    const [isSolving, setIsSolving] = useState(false);
    const handleChange = (newValue: any) => {
        setValue(newValue as number);
    };
    const handleConnectionNumberChange = (event: any) => {
        setAllowedConnectionNumber(event.target.value);
    };
    const PomorskieData=async ()=>{
        const response = await fetch('/dataSets/pomorskie.csv');
        const reader = response.body!.getReader();
        const data = await reader.read();
        const decoder = new TextDecoder();
        const csvString = decoder.decode(data.value!)
        console.log(csvString)
        const workbook = XLSX.read(csvString, {type: "string"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        setData(parsedData);
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    const handleDijykstra = async (startNode: any, destinationNode: any) => {
        graphReset()
        setIsSolving(true)
        const Queue = new PriorityQueue<string>();
        const set = new Set<string>()
        const g: { [key: string]: number } = {};
        const previous: { [key: string]: string | null } = {};
        for (let i = 0; i < graphRef.current.nodes().length; i++) {
            g[graphRef.current.nodes()[i]] = Number.MAX_VALUE;
            previous[graphRef.current.nodes()[i]] = null;
        }
        Queue.enqueue(startNode, 0);
        g[startNode] = 0;
        while (!Queue.isEmpty()) {
            const u = Queue.dequeue();
            graphRef.current.setNodeAttribute(u?.element, "color", traversedEdgeColor);
            if (previous[u!!.element] && previous[u!!.element] !== null) {
                graphRef.current.setEdgeAttribute(u?.element, previous[u!!.element], "color", traversedEdgeColor);
            }
            // @ts-ignore
            set.add(u?.element);
            if (u?.element === destinationNode) {
                console.log(previous)
                setIsSolving(false)
                let node = destinationNode
                let path = [];
                while (node) {
                    path.push(node);
                    node = previous[node];
                }
                graphRef.current.setNodeAttribute(path[0], "color", routeEdgeColor);
                for (let i = 1; i < path.length; i++) {
                    graphRef.current.setNodeAttribute(path[i], "color", routeEdgeColor);
                    graphRef.current.setEdgeAttribute(path[i], path[i - 1], "color", routeEdgeColor);
                }
                return path.reverse();
            }
            for (const neighbour of graphRef.current.neighbors(u?.element)) {
                if (set.has(neighbour)) {
                    continue;
                }
                await sleep(100)
                graphRef.current.setNodeAttribute(neighbour, "color", traversedEdgeColor);
                graphRef.current.setEdgeAttribute(u!.element, neighbour, "color", traversedEdgeColor);
                // @ts-ignore
                const alt = g[u?.element] + heuristic(neighbour, u?.element)
                if (alt < g[neighbour]) {
                    g[neighbour] = alt;
                    Queue.enqueue(neighbour, alt);
                    previous[neighbour] = u!!.element;
                }
            }
        }
        setIsSolving(false)
    };

    const handleSolveAStar = async (startNode: any, destinationNode: any) => {
        graphReset()
        setIsSolving(true)
        const Queue = new PriorityQueue<string>();
        const set = new Set<string>()
        const g: { [key: string]: number } = {};
        const previous: { [key: string]: string | null } = {};
        for (let i = 0; i < graphRef.current.nodes().length; i++) {
            g[graphRef.current.nodes()[i]] = Number.MAX_VALUE;
            previous[graphRef.current.nodes()[i]] = null;
        }
        Queue.enqueue(startNode, 0);
        g[startNode] = 0;
        while (!Queue.isEmpty()) {
            const u = Queue.dequeue();
            graphRef.current.setNodeAttribute(u?.element, "color", traversedEdgeColor);
            if (previous[u!!.element] && previous[u!!.element] !== null) {
                graphRef.current.setEdgeAttribute(u?.element, previous[u!!.element], "color", traversedEdgeColor);
            }
            // @ts-ignore
            set.add(u?.element);
            if (u?.element === destinationNode) {
                console.log(previous)
                setIsSolving(false)
                let node = destinationNode
                let path = [];
                while (node) {
                    path.push(node);
                    node = previous[node];
                }
                graphRef.current.setNodeAttribute(path[0], "color", routeEdgeColor);
                for (let i = 1; i < path.length; i++) {
                    graphRef.current.setNodeAttribute(path[i], "color", routeEdgeColor);
                    graphRef.current.setEdgeAttribute(path[i], path[i - 1], "color", routeEdgeColor);
                }
                return path.reverse();
            }
            for (const neighbour of graphRef.current.neighbors(u?.element)) {
                await sleep(100)
                graphRef.current.setNodeAttribute(neighbour, "color", traversedEdgeColor);
                graphRef.current.setEdgeAttribute(u!.element, neighbour, "color", traversedEdgeColor);
                // @ts-ignore
                const alt = g[u?.element] + heuristic(neighbour, u?.element)
                if (alt < g[neighbour]) {
                    g[neighbour] = alt;
                    const f = g[neighbour] + heuristic(neighbour, destinationNode);
                    Queue.enqueue(neighbour, f);
                    previous[neighbour] = u!!.element;
                }
            }

        }

        return null;
    };

    const heuristic = (nodeA: any, nodeB: any) => {
        return heuristicCalc(graphRef, nodeB, nodeA);
    }

    const handleSolveBFS = async (startNode: string | null | undefined, targetNode: string | null | undefined) => {

        graphReset()
        setIsSolving(true)
        stopSolving = false;
        const queue = [[startNode]];
        const visited = new Set();
        let steps = 0;

        while (queue.length > 0) {
            if (stopSolving) {
                graphReset()
                setIsSolving(false)
                break;
            }
            const path = queue.shift()!;

            const vertex = path[path.length - 1];
            if (vertex === targetNode) {
                steps++;
                console.log("Shortest path: ", path);
                graphRef.current.setNodeAttribute(startNode, "color", "yellow");
                for (let i = 1; i < path.length; i++) {
                    graphRef.current.setNodeAttribute(path[i], "color", routeEdgeColor);
                    graphRef.current.setEdgeAttribute(path[i], path[i - 1], "color", routeEdgeColor);
                }
                // @ts-ignore
                returnData.bfs = {
                    pathSteps: path.length,
                    shortestPath: path,
                    steps: steps
                };
                setReturnData(returnData)
                graphRef.current.setNodeAttribute(endNode, "color", "blue")
                setIsSolving(false)

                return path;
            }

            if (!visited.has(vertex)) {
                steps++;
                graphRef.current.setNodeAttribute(vertex, "color", traversedEdgeColor);
                if (vertex === startNode) {
                    graphRef.current.setNodeAttribute(startNode, "color", "yellow")
                }
                if (path.length > 1) {
                    const parentNode = path[path.length - 2];
                    const currentNode = path[path.length - 1];
                    const edge = graphRef.current.edge(parentNode, currentNode)
                    graphRef.current.setEdgeAttribute(edge, "color", traversedEdgeColor);
                }
                visited.add(vertex);
                await sleep(100);

                for (const neighbor of graphRef.current.neighbors(vertex)) {
                    if (!visited.has(neighbor)) {
                        const newPath = [...path, neighbor];
                        queue.push(newPath);
                    }
                }
            }
        }
        setIsSolving(false)

        console.log("No path found");
        return null;
    };


    const handleSolveDFS = async (startNode: string | null | undefined, targetNode: string | null | undefined) => {

        setIsSolving(true)
        graphReset()
        stopSolving = false;
        const stack = [[startNode]];
        const visited = new Set();
        let steps = 0;
        while (stack.length > 0) {
            if (stopSolving) {
                graphReset()
                setIsSolving(false)

                break;
            }
            const path = stack.pop()!;
            const vertex = path[path.length - 1];
            steps++;
            if (vertex === targetNode) {
                console.log("Found target path: ", path);
                graphRef.current.setNodeAttribute(startNode, "color", "yellow");
                for (let i = 1; i < path.length; i++) {
                    graphRef.current.setNodeAttribute(path[i], "color", routeEdgeColor);
                    graphRef.current.setEdgeAttribute(path[i], path[i - 1], "color", routeEdgeColor);
                }
                graphRef.current.setNodeAttribute(endNode, "color", "blue")
                setIsSolving(false)
                returnData.dfs = {
                    pathSteps: path.length,
                    shortestPath: path,
                    steps: steps
                };
                setReturnData(returnData)
                return path;
            }

            if (!visited.has(vertex)) {
                graphRef.current.setNodeAttribute(vertex, "color", traversedEdgeColor);
                if (path.length > 1) {
                    const parentNode = path[path.length - 2];
                    const currentNode = path[path.length - 1];
                    const edge = graphRef.current.edge(parentNode, currentNode);
                    graphRef.current.setEdgeAttribute(edge, "color", traversedEdgeColor);
                }
                visited.add(vertex);
                await sleep(100);

                const neighbors = graphRef.current.neighbors(vertex);
                for (let i = neighbors.length - 1; i >= 0; i--) {
                    const neighbor = neighbors[i];
                    if (!visited.has(neighbor)) {
                        const newPath = [...path, neighbor];
                        stack.push(newPath);
                    }
                }
            }
        }
        setIsSolving(false)

    };
    const handleFileUpload = (event: any) => {
        const reader = new FileReader();
        const file = event.target.files[0];
        if (file) {
            readFileData(reader, file, setData);
        }
    };
    //TODO: dodać poprawny export danych
    const handleExport = () => {
        exportHandler(returnData);
    }

    function graphReset() {
        let {
            maxDistance,
            minDistance
        } = graphWholeReset(graphRef, data, haversineDistance, value, allowedConnectionNumber, startNode, endNode);

        setMaxSlider(maxDistance);
        setMinSlider(minDistance);
    }

    useEffect(() => {
        graphReset();
    }, [data, value, allowedConnectionNumber, startNode, endNode]);
    const GraphEvents: React.FC = () => {
        const registerEvents = useRegisterEvents();

        useEffect(() => {
            registerEvents({
                clickNode: (event) => {
                    if (!startIsSet) {
                        setStartIsSet(true);
                        setStartNode(event.node)
                    } else {
                        setStartIsSet(false);
                        setEndNode(event.node)
                    }
                },
                clickEdge: (event) => {
                    if (blockedEdges.includes(event.edge)) {
                        setBlockedEdges(blockedEdges.filter(item => item !== event.edge));
                    } else {
                        setBlockedEdges([...blockedEdges, event.edge])
                    }
                    console.log(blockedEdges)
                },

            });
        }, [registerEvents]);

        return null;
    };
    const graphRef = useRef(
        new Graph({allowSelfLoops: false, type: "undirected"}),
    );
    const handleBreakSolving = () => {
        stopSolving = true;
        graphReset();
        setIsSolving(false);
    }
    const blackTheme = createTheme({
        palette: {
            mode: 'dark',
            background: {
                default: '#000000',
                paper: '#121212',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });
//TODO: Dodać wybieranie kolumn które mają oznaczać odpowiednie rzeczy z csv to do handle file upload
    return (
        <ThemeProvider theme={blackTheme}>
            <CssBaseline/>
            <Button onClick={()=> PomorskieData()}>POMORSKIE</Button>
            <div>
                <Input type="file" inputProps={{accept: ".xlsx,.xls,.csv"}} onChange={handleFileUpload}/>
                <Input type="number" onChange={handleConnectionNumberChange}/>
                <SigmaContainer
                    settings={{
                        enableEdgeEvents: true,
                        defaultEdgeColor: normalEdgeColor,
                        defaultNodeColor: "green",
                        labelWeight: "bold",
                        labelColor: {color: "grey"}
                    }}
                    style={{height: "80vh", width: "100vw", background: "black"}}
                    graph={graphRef.current}
                >
                    <GraphEvents/>
                </SigmaContainer>
                <DistanceInput min={minSlider} max={maxSlider} onChange={handleChange}></DistanceInput>
                <Button disabled={isSolving || !startNode || !endNode}
                        onClick={() => handleSolveBFS(startNode, endNode)}>solve bfs</Button>
                <Button disabled={isSolving || !startNode || !endNode}
                        onClick={() => handleSolveDFS(startNode, endNode)}>solve dfs</Button>
                <Button disabled={isSolving || !startNode || !endNode}
                        onClick={() => handleSolveAStar(startNode, endNode)}>solve a*</Button>
                <Button disabled={isSolving || !startNode || !endNode}
                        onClick={() => handleDijykstra(startNode, endNode)}>solve Dijykstra</Button>
                <Button disabled={!isSolving || !startNode || !endNode} onClick={handleBreakSolving}>stop</Button>
                <Button onClick={handleExport}>export data</Button>
            </div>
        </ThemeProvider>
    );
}

export default App;
